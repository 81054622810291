import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Profile from "./components/profile";
// import Admin from "./components/admin/admin";
// import { ProtectedRoute } from "./components/admin/ProtectedRoute";
// import  ProtectedRoutes  from "./components/admin/ProtectedRoute";
// import DemoAdmin from "./components/demo/admin";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Profile />} />
      {/* <Route element={<ProtectedRoutes />}>
        <Route path="/admin/home" element={<Admin />} />
        <Route path="/admin/:id" element={<Admin />} />
      </Route>
      <Route path="/demo/home" exact element={<DemoAdmin />} />
      <Route path="/demo/:id" element={<DemoAdmin />} /> */}
    </Routes>
  );
}

export default App;
