import axios from "axios";
import { useEffect, useState } from "react";
// import auth from "./admin/auth";
import "./profile.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Fade} from "react-reveal";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import expPic from "../exp.png";
import dpPic from "../pic.jpeg";
import * as uuid from "uuid";

axios.defaults.withCredentials = false;

const url = "https://faas-tor1-70ca848e.doserverless.co/api/v1/web/fn-417a81e2-e46f-4216-913e-27f2344141ec/mongodb/postData?section=";

function Profile(props) {
  window.onload = function () {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  const [errormsg, seterror] = useState(0);
  const [cred, setLogin] = useState({ name: "", password: "" });
  const [bio, setbio] = useState({ name: "" });
  const [edu, setedu] = useState([]);
  const [exp, setexp] = useState([]);
  const [pro, setpro] = useState([]);
  const [ski, setski] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    crossDomain: true
  };

  // async function login(props) {
  //   await auth.login(cred.name, cred.password);
  //   const x = window.sessionStorage.getItem("authenticated");
  //   if (x === "true") {
  //     props.history.push("/admin/home");
  //   } else {
  //     seterror((prev) => prev + 1);
  //   }
  // }

  async function fetcher() {
    axios.get(url + "bio", config).then(async (response) => {
      setbio(response.data[0]);
    });
    axios.get(url + "Education", config).then(async (response) => {
      setedu(response.data);
    });
    axios.get(url + "Experience", config).then(async (response) => {
      setexp(response.data);
    });
    axios.get(url + "Projects", config).then(async (response) => {
      setpro(response.data);
    });
    axios.get(url + "Skills", config).then(async (response) => {
      setski(response.data);
    });
  }

  var eduItems = (edu || []).map((item) => (
    <Fade key={item._id} bottom>
      <div className="eduEle">
        <div style={{ height: "min-content" }}>
          <h3>{item.university}</h3>
          <h4>{item.degree}</h4>
          <h6 style={{ fontStyle: "italic" }}>
            {item.datefrom
              ? new Date(item.datefrom).toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })
              : "Present"}{" "}
            -{" "}
            {item.datefrom
              ? new Date(item.dateto).toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })
              : "Present"}
          </h6>
          <h5>Relevant courses : </h5>
          <h6 style={{ whiteSpace: "pre-wrap", lineHeight: "1.5em" }}>
            {item.courses}
          </h6>
          <h5>Achievements: </h5>
          <h6>{item.achievements}</h6>
        </div>
      </div>
    </Fade>
  ));

  var skiItems = (ski || []).map((item) => (
    <Fade key={item._id} bottom>
      <div>
        <h4 style={{ margin: "10px", padding: "10px" }}>{item.type}</h4>
        <h6 style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {item.skills.map((skills) => {
            return (
              <span
                key={uuid.v4()}
                className="post"
                style={{
                  border: "1px solid black",
                  margin: "5px",
                  padding: "10px",
                  borderRadius: "3px",
                }}
              >
                <span className="post-title">{skills.skill}</span>
              </span>
            );
          })}
        </h6>
      </div>
    </Fade>
  ));

  var expItems = (exp || []).map((item) => (
    <Fade key={item._id} bottom>
      <div className="expEle">
        <h3>{item.company}</h3>

        <h5 style={{ fontStyle: "italic" }}>{item.role}</h5>
        <h6>
          {item.datefrom
            ? new Date(item.datefrom).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : "Present"}{" "}
          -{" "}
          {item.datefrom
            ? new Date(item.dateto).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : "Present"}
        </h6>
        <p style={{ whiteSpace: "pre-wrap" }}>{item.description}</p>
      </div>
    </Fade>
  ));

  var proItems = (pro || []).map((item) => (
    <Fade key={item._id} left>
      <div className="proEle">
        <h3>{item.name}</h3>
        <h5>{item.role}</h5>
        <h6 style={{ fontStyle: "italic" }}>
          {item.datefrom
            ? new Date(item.datefrom).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : "Present"}{" "}
          -{" "}
          {item.datefrom
            ? new Date(item.dateto).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : "Present"}
        </h6>
        <p style={{ whiteSpace: "pre-wrap" }}>{item.description}</p>
      </div>
    </Fade>
  ));

  // const ModalContent = () => {
  //   return (
  //     <Modal
  //       show={show}
  //       onHide={handleClose}
  //       centered
  //       style={{ height: "100%" }}
  //     >
  //       <Form
  //         noValidate
  //         style={{ height: "100%" }}
  //         onSubmit={() => login(props)}
  //         className="modalas"
  //       >
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //             justifyContent: "space-evenly",
  //           }}
  //         >
  //           <h3>Admin Login</h3>
  //           OR
  //           <button
  //             className="login"
  //             style={{ padding: "10px" }}
  //             onClick={() => {
  //               props.history.push("/demo/home");
  //             }}
  //           >
  //             Sign In as Guest
  //           </button>
  //         </div>
  //         {errormsg === 0 ? null : (
  //           <div
  //             style={{
  //               padding: "5px",
  //               margin: "auto",
  //               marginBottom: "10px",
  //               background: "rgba(255, 72, 72, 0.411)",
  //               border: "1px solid red",
  //               width: "fit-content",
  //               borderRadius: "5px",
  //             }}
  //           >
  //             Credentials are wrong
  //           </div>
  //         )}
  //         <Form.Group md="4">
  //           <InputGroup hasValidation className="mb-3">
  //             <InputGroup.Text>
  //               <InputGroup.Text id="a">Username</InputGroup.Text>
  //             </InputGroup.Text>
  //             <Form.Control
  //               type="text"
  //               placeholder="Username"
  //               aria-describedby="inputGroupText"
  //               defaultValue=""
  //               onChange={(e) => (cred.name = e.target.value)}
  //               required
  //             />
  //             <Form.Control.Feedback type="invalid">
  //               Type your name here
  //             </Form.Control.Feedback>
  //           </InputGroup>
  //           <InputGroup hasValidation className="mb-3">
  //             <InputGroup.Text>
  //               <InputGroup.Text id="b">Password</InputGroup.Text>
  //             </InputGroup.Text>
  //             <Form.Control
  //               type="text"
  //               placeholder="Password"
  //               aria-describedby="inputGroupText"
  //               defaultValue=""
  //               onChange={(e) => (cred.password = e.target.value)}
  //               required
  //             />
  //           </InputGroup>
  //         </Form.Group>
  //         <div
  //           onClick={() => login(props)}
  //           className="button"
  //           style={{ margin: "auto" }}
  //         >
  //           Login
  //         </div>
  //       </Form>
  //     </Modal>
  //   );
  // };

  useEffect(() => {
    fetcher();
  }, []);

  return (
    <div className="App">
      <ParallaxProvider>
        {/* <ModalContent />
        <div className="login-div">
          <button
            className="login"
            style={{ padding: "10px", margin: "10px" }}
            onClick={() => {
              window.sessionStorage.getItem("authenticated") === "true"
                ? props.history.push("/admin/home")
                : handleShow();
            }}
          >
            <i className="fas fa-user" style={{ color: "white" }}></i>
          </button>
        </div> */}
        <section>
          <Fade left wait={5000}>
            <Parallax className="bgtext" x={[100, 180]}>
              <div>Bio</div>
            </Parallax>
          </Fade>
          <div className="grid-section">
            <Fade top>
              <div className="dp">
                <img className="dpPic" src={dpPic} alt="bruh" />
              </div>
            </Fade>
            <div className="info">
              <div
                style={{
                  fontFamily: "'Playfair Display', serif",
                  fontSize: "max(7vh,30px)",
                }}
              >
                Hi, my name is {bio.name}
              </div>
              <div
                style={{
                  fontFamily: "'Domine', serif",
                  fontSize: "max(2.5vh,15px)",
                  paddingRight: "20px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {bio.description}
              </div>
              <div
                style={{
                  fontSize: "max(2vh,13px)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Contact Me @
                <a href={`mailto:${bio.email}`}>
                  <i className="fas fa-envelope fa-3x"></i>
                </a>
                <a href={bio.github} target="_blank" rel="noreferrer">
                  <i className="fab fa-github-square fa-3x"></i>
                </a>
                <a href={bio.linkedin} target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin fa-3x"></i>
                </a>
              </div>
              <div style={{ fontSize: "max(2vh,13px)", marginTop: "10px" }}>
                Hire me?{" "}
                <a
                  href={bio.resume}
                  className="button"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    backgroundColor: "black",
                  }}
                >
                  {" "}
                  Resume{" "}
                </a>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: "10vh" }}>
          <Fade left>
            <Parallax className="bgtext" x={[-10, 50]}>
              <div>Education</div>
            </Parallax>
          </Fade>
          <div className="education-grid">
            <Fade bottom>
              <div
                style={{
                  fontSize: "3em",
                  fontWeight: "1000",
                  marginTop: "1.5em",
                  marginLeft: "1.5em",
                }}
              >
                Education
              </div>
            </Fade>
            {eduItems}
            <div className="skillset">
              <h3>Skills : </h3>
              {skiItems}
            </div>
          </div>
        </section>
        <div className="picons">
          <Fade left>
            <div>
              <i
                className="fas fa-university fa-3x"
                style={{ color: "#00ccff" }}
              />
            </div>
          </Fade>
          <Fade right>
            <div>
              <i className="fas fa-code fa-5x" style={{ color: "#c200c2" }} />
            </div>
          </Fade>
          <Fade left>
            <div>
              <i
                className="fas fa-code-branch fa-2x"
                style={{ color: "#ff0066" }}
              />
            </div>
          </Fade>
          <Fade right>
            <div>
              <i
                className="fas fa-terminal fa-3x"
                style={{ color: "#6600ff" }}
              />
            </div>
          </Fade>
          <Fade left>
            <div>
              <i className="fas fa-server fa-4x" style={{ color: "#33cc33" }} />
            </div>
          </Fade>
          <Fade right>
            <div>
              <i
                className="fas fa-user-graduate fa-2x"
                style={{ color: "#ff9900" }}
              />
            </div>
          </Fade>
          <Fade left>
            <div>
              <i className="fas fa-book fa-5x" style={{ color: "#cc0066" }} />
            </div>
          </Fade>
        </div>

        <section style={{ marginTop: "10vh" }}>
          <Fade left>
            <Parallax className="bgtext" x={[-10, 50]}>
              <div>Experience</div>
            </Parallax>
          </Fade>
          <div className="exp-grid">
            <Fade bottom>
              <div
                style={{
                  fontSize: "3em",
                  fontWeight: "1000",
                  margin: "1.5em",
                  textAlign: "left",
                }}
              >
                Experience
              </div>
            </Fade>
            <div className="expCont">{expItems}</div>
            <img className="exp-bg" src={expPic} alt="bruh" />
          </div>
          <div className="picons">
            <Fade top>
              <div>
                <i
                  className="fas fa-tools fa-3x"
                  style={{ color: "#c200c2" }}
                />
              </div>
            </Fade>
            <Fade bottom>
              <div>
                <i
                  className="fas fa-briefcase fa-5x"
                  style={{ color: "#00ccff" }}
                />
              </div>
            </Fade>
            <Fade top>
              <div>
                <i className="fas fa-code fa-2x" style={{ color: "#ff0066" }} />
              </div>
            </Fade>
            <Fade bottom>
              <div>
                <i
                  className="fas fa-server fa-3x"
                  style={{ color: "#33cc33" }}
                />
              </div>
            </Fade>
            <Fade top>
              <div>
                <i
                  className="fas fa-pencil-alt fa-4x"
                  style={{ color: "#cc0066" }}
                />
              </div>
            </Fade>
            <Fade bottom>
              <div>
                <i
                  className="fas fa-terminal fa-2x"
                  style={{ color: "#6600ff" }}
                />
              </div>
            </Fade>
            <Fade top>
              <div>
                <i
                  className="fas fa-laptop-code fa-5x"
                  style={{ color: "#ff9900" }}
                />
              </div>
            </Fade>
          </div>
        </section>

        <section
          style={{
            marginTop: "10vh",
            position: "absolute",
            minHeight: "100vh",
          }}
        >
          <Fade left>
            <Parallax className="bgtext" x={[-10, 50]}>
              <div>Projects</div>
            </Parallax>
          </Fade>
          <div className="pro-grid">
            <Fade bottom>
              <div
                style={{ fontSize: "3em", fontWeight: "1000", margin: "1.5em" }}
              >
                Projects
              </div>
            </Fade>
            <div className="projectsCont">{proItems}</div>
          </div>
          <div className="footer">
            Copyright &copy; {new Date().getFullYear()} Bhairaw Aryan. All
            rights reserved.
          </div>
        </section>
      </ParallaxProvider>
    </div>
  );
}

export default Profile;
